<!-- 福彩3D万能码5码走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>排 3 万 能 码 （五 码） 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a ">期号</th>
                        <th rowspan="2" class="ranks_a1">开奖</th>
                        <th colspan="1" class="ranks_b" v-for="(item , i) in zu" :key="i">
                            {{item.data[0]}}{{item.data[1]}}{{item.data[2]}}{{item.data[3]}}{{item.data[4]}}
                        </th>
                        <th colspan="10" class="ranks_b">尾数走势图</th>
                        <th colspan="3" class="ranks_b">尾数012路</th>
                        <th colspan="3" class="ranks_b">尾数147路</th>
                        <th colspan="3" class="ranks_b">尾数258路</th>
                        <th colspan="2" class="ranks_b">奇 偶 </th>
                        <th colspan="2" class="ranks_b">大 小 </th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="5" class="ranks_b">五行走势图</th>
                    </tr>

                    <tr>
                        <th class="ranks_c" v-for="(item , i ) in 17" :key="i">{{item}}</th>

                        <th class="ranks_c">00</th>
                        <th class="ranks_c">01</th>
                        <th class="ranks_c">02</th>
                        <th class="ranks_c">03</th>
                        <th class="ranks_c">04</th>
                        <th class="ranks_c">05</th>
                        <th class="ranks_c">06</th>
                        <th class="ranks_c">07</th>
                        <th class="ranks_c">08</th>
                        <th class="ranks_c">09</th>

                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">金</th>
                        <th class="ranks_c">木</th>
                        <th class="ranks_c">水</th>
                        <th class="ranks_c">火</th>
                        <th class="ranks_c">土</th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in pl35" :key='x'>
                        <td>{{item2.expect}}</td>
                        <td>
                            <div class="ranks_a12">
                                {{item2.red[0] }} {{item2.red[1] }} {{item2.red[2] }}
                            </div>
                        </td>
                        <td class="b-red" v-for="(item ,i) in q2" :key='i'>
                            <div :class="item==item1 ? 'q-red' : 'no' " v-for="(item1 ,j) in kai[x]" :key='j'>
                                {{item==item1 ? item : ''}}
                            </div>
                        </td>
                        <!-- 尾数 -->
                        <td class="b-lan" v-for="(item ,i) in q1" :key='i'>
                            <div :class="kai2[x][x,j]==item ? 'q-lan' : 'no' " v-for="(item1 ,j) in kai2[x]" :key='j'>
                                {{kai2[x][x,j]==item ? item : ''}}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-red" v-for="(item ,i) in lye" :key='i'>
                            <div :class=" item ==item1%10%3 ?'q-red':'no' " v-for="(item1 ,j) in lye1[x]" :key='j'>
                                {{ item ==item1%10%3 ?item:'' }}
                            </div>
                        </td>
                        <!-- 147路 -->
                        <td class="b-lan" v-for="(item ,i) in ysq" :key='i'>
                            <div :class="item == (([1,5,6,9].indexOf(item1)==-1 ? '' : 1)||
                            ([0,2,4].indexOf(item1)==-1 ? '' : 4)||([3,7,8].indexOf(item1)==-1 ? '' : 7) )
                            ? 'q-lan' : 'no'" v-for="(item1 ,j) in ysq1[x]" :key='j'>
                                {{ item == (([1,5,6,9].indexOf(item1)==-1 ? '' : 1)||
                                ([0,2,4].indexOf(item1)==-1 ? '' : 4)||([3,7,8].indexOf(item1)==-1 ? '' : 7) )
                                ? item : '' }}
                            </div>
                        </td>
                        <!-- 258路 -->
                        <td class="b-red" v-for="(item ,i) in ewb" :key='i'>
                            <div :class="item == (([2,7,9].indexOf(item1)==-1 ? '' : 2)||([0,3,5].indexOf(item1)==-1 ? '':5)||([1,4,6,8].indexOf(item1)==-1 ? '' : 8) ) ? 'q-red' : 'no'"
                                v-for="(item1 ,j) in ewb1[x]" :key='j'>
                                {{ item == (([2,7,9].indexOf(item1)==-1 ? '' : 2)||([0,3,5].indexOf(item1)==-1 ?
                                '':5)||([1,4,6,8].indexOf(item1)==-1 ? '' : 8) ) ? item : '' }}
                            </div>
                        </td>
                        <!-- 奇偶 -->
                        <td class="b-lan" v-for="(item ,i) in jo" :key='i'>
                            <div :class="item == item1? 'q-lan' : 'no'" v-for="(item1 ,j) in jo1[x]" :key='j'>
                                {{ item == item1? item : '' }}
                            </div>
                        </td>
                        <!-- 大小 -->
                        <td class="b-red" v-for="(item ,i) in dx" :key='i'>
                            <div :class="item == item1 ? 'q-red' : 'no'" v-for="(item1 ,j) in dx1[x]" :key='j'>
                                {{ item == item1 ? item : '' }}
                            </div>
                        </td>
                        <!-- 质合 -->
                        <td class="b-lan" v-for="(item ,i) in zh" :key='i'>
                            <div :class="item == item1 ? 'q-lan' : 'no' " v-for="(item1 ,j) in zh1[x]" :key='j'>
                                {{ item == item1 ? item : '' }}
                            </div>
                        </td>
                        <!-- 五行走势图 -->
                        <td class="b-red" v-for="(item ,i) in wx" :key='i'>
                            <div :class="item ==item1? 'q-red' : 'no'" v-for="(item1 ,j) in wx2[x]" :key='j'>
                                {{ item ==item1? item : '' }}
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td v-for="(x , i) in 47" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 47" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 47" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
     import mitt from '@/js/mitt.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    //import api from '@/js/mpublic'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                q1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                q2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
                kai: [],
                kai2: [],
                red0: [],
                lye: [0, 1, 2],
                lye1: [],
                ysq: [1, 4, 7],
                ysq1: [],
                ewb: [2, 5, 8],
                ewb1: [],
                jo: ["奇", "偶"],
                jo1: [],
                dx: ["大", "小"],
                dx1: [],
                zh: ["质", "合"],
                zh1: [],
                zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                wx2: [],
                wx: ['金', '木', '水', '火', '土'],
                zu: [
                    { data: [0, 1, 2, 4, 9] },
                    { data: [0, 1, 2, 6, 8] },
                    { data: [0, 1, 3, 4, 6] },
                    { data: [0, 1, 4, 6, 7] },
                    { data: [0, 1, 5, 6, 9] },
                    { data: [0, 2, 3, 5, 7] },
                    { data: [0, 2, 4, 5, 8] },
                    { data: [0, 3, 7, 8, 9] },
                    { data: [1, 2, 3, 5, 9] },
                    { data: [1, 2, 3, 7, 8] },
                    { data: [1, 2, 5, 8, 9] },
                    { data: [1, 3, 4, 7, 8] },
                    { data: [1, 4, 5, 7, 9] },
                    { data: [2, 3, 4, 5, 6] },
                    { data: [2, 4, 6, 7, 9] },
                    { data: [3, 4, 6, 8, 9] },
                    { data: [3, 5, 6, 7, 8] },

                ]
            }
        },
        mounted() {
            this.wnm()
            this.mlye()
            this.mysq()
            this.mewb()
            this.mjo()
            this.mdx()
            this.mzh()
            this.mwx()
            this.find()
        },
        computed: {
            pl35() {
                return this.$store.state.pl35;
            },
        },
        methods: { // 函数体部分，js的主要逻辑控制  
            find() {
                document.documentElement.scrollTop = 0;
                if (this.pl35 == '') {
                    mitt.emit('pl35');
                }
            }, 
            wnm() {  //综合分布走势
                this.kai=[]
                this.kai2=[]
                for (var i = 0; i < this.pl35.length; i++) {
                    var arr = new Array()
                    var arr2 = new Array()
                    for (var y = 0; y < this.zu.length; y++) {
                        //console.log(this.zu[y].data)
                        if (this.zu[y].data.indexOf(parseInt(this.pl35[i].red[0])) > -1 && this.zu[y].data.indexOf(parseInt(this.pl35[i].red[1])) > -1 && this.zu[y].data.indexOf(parseInt(this.pl35[i].red[2])) > -1) {
                            arr.push(y + 1)//尾数未去重
                            if (arr2.indexOf((y + 1) % 10) == -1) {
                                arr2.push((y + 1) % 10)//尾数去重                              
                            }

                        }
                    }
                    this.kai.push(arr)
                    this.kai2.push(arr2)

                }
                // console.log(this.wx2)
                // console.log(this.kai2)
            },

            mlye() { //012路
                this.lye1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if (xx.indexOf(this.kai[i][i, j] % 10 % 3) == -1) {
                            xx.push(this.kai[i][i, j] % 10 % 3);
                        }
                    }
                    this.lye1.push(xx);
                }
                // console.log(this.lye1)
            },

            mysq() {  //147路
                this.ysq1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if (
                            this.kai[i][i, j] % 10 == 1 ||
                            this.kai[i][i, j] % 10 == 5 ||
                            this.kai[i][i, j] % 10 == 6 ||
                            this.kai[i][i, j] % 10 == 9
                        ) {
                            if (xx.indexOf(1) == -1) {
                                xx.push(1);
                            }
                        } else if (
                            this.kai[i][i, j] % 10 == 0 ||
                            this.kai[i][i, j] % 10 == 2 ||
                            this.kai[i][i, j] % 10 == 4
                        ) {
                            if (xx.indexOf(4) == -1) {
                                xx.push(4);
                            }
                        } else if (
                            this.kai[i][i, j] % 10 == 3 ||
                            this.kai[i][i, j] % 10 == 7 ||
                            this.kai[i][i, j] % 10 == 8
                        ) {
                            if (xx.indexOf(7) == -1) {
                                xx.push(7);
                            }
                        }
                    }
                    this.ysq1.push(xx);
                }
            },

            mewb() {  //258路
                this.ewb1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if (
                            this.kai[i][j] % 10 == 2 ||
                            this.kai[i][j] % 10 == 7 ||
                            this.kai[i][j] % 10 == 9
                        ) {
                            if (xx.indexOf(2) == -1) {
                                xx.push(2);
                            }
                        } else if (
                            this.kai[i][j] % 10 == 0 ||
                            this.kai[i][j] % 10 == 3 ||
                            this.kai[i][j] % 10 == 5
                        ) {
                            if (xx.indexOf(5) == -1) {
                                xx.push(5);
                            }
                        } else if (
                            this.kai[i][j] % 10 == 1 ||
                            this.kai[i][j] % 10 == 4 ||
                            this.kai[i][j] % 10 == 6 ||
                            this.kai[i][j] % 10 == 8
                        ) {
                            if (xx.indexOf(8) == -1) {
                                xx.push(8);
                            }
                        }
                    }
                    this.ewb1.push(xx);
                }
            },

            mjo() {  //奇偶
                this.jo1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if (this.kai[i][i, j] % 2 == 0) {
                            if (xx.indexOf("偶") == -1) {
                                xx.push("偶");
                            }
                        } else {
                            if (xx.indexOf("奇") == -1) {
                                xx.push("奇");
                            }
                        }
                    }
                    this.jo1.push(xx);
                }
            },

            mdx() {  //大小
                this.dx1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if (this.kai[i][i, j] % 10 < 5) {
                            if (xx.indexOf("小") == -1) {
                                xx.push("小");
                            }
                        } else {
                            if (xx.indexOf("大") == -1) {
                                xx.push("大");
                            }
                        }
                    }
                    this.dx1.push(xx);
                }
            },

            mzh() {  //质合
                this.zh1=[]
                for (var i = 0; i < this.kai.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.kai[i].length; j++) {
                        if ([1, 2, 3, 5, 7].indexOf(this.kai[i][i, j] % 10) == -1) {
                            if (xx.indexOf("合") == -1) {
                                xx.push("合");
                            }
                        } else {
                            if (xx.indexOf("质") == -1) {
                                xx.push("质");
                            }
                        }
                    }
                    this.zh1.push(xx);
                }
            },

            mwx() {//五行
                this.wx2=[]
                for (var i = 0; i < this.kai2.length; i++) {
                    var arr = new Array();
                    for (var j = 0; j < this.kai2[i].length; j++) {
                        if (this.kai2[i][i, j] == 1 || this.kai2[i][i, j] == 9) {
                            if (arr.indexOf('金') == -1) {
                                arr.push('金')
                            }
                        } if (this.kai2[i][i, j] == 2 || this.kai2[i][i, j] == 8) {
                            if (arr.indexOf('木') == -1) {
                                arr.push('木')
                            }
                        } if (this.kai2[i][i, j] == 3 || this.kai2[i][i, j] == 7) {
                            if (arr.indexOf('水') == -1) {
                                arr.push('水')
                            }
                        } if (this.kai2[i][i, j] == 4 || this.kai2[i][i, j] == 6) {
                            if (arr.indexOf('火') == -1) {
                                arr.push('火')
                            }
                        } if (this.kai2[i][i, j] == 0 || this.kai2[i][i, j] == 5) {
                            if (arr.indexOf('土') == -1) {
                                arr.push('土')
                            }
                        }

                    }

                    this.wx2.push(arr)

                    // console.log(this.kai2)
                }
            }



        },
        props: {

        },
        watch: {
            pl35() {
                this.wnm()
                this.mlye()
                this.mysq()
                this.mewb()
                this.mjo()
                this.mdx()
                this.mzh()
                this.mwx()
            }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a12 {
        width: 30px;
    }

    .ranks_c {

        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        background-color: rgb(191, 224, 191);
    }

    .b-lan {
        background-color: #f8fcb2;
    }


    .q-red {
        background-color: rgb(87, 28, 114);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: #548ce0;
        border-radius: 15px;
        color: #fff;
    }

    .q-he {
        background-color: #22940b;
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    .no {
        display: none;
    }
</style>